import React from 'react';

// Component Imports
import Section from '../shared/Section/Section';

// Style Imports
import './Social.scss';

// Asset imports
import Facebook from '../../assets/images/icons/facebook-white.svg';
import Twitter from '../../assets/images/icons/twitter-white.svg';
import Instagram from '../../assets/images/icons/instagram-white.svg';
import Youtube from '../../assets/images/icons/youtube-white.svg';
import Patreon from '../../assets/images/icons/patreon-white.svg';

const Social = () => {
    return (
        <Section className='section--navy section--chevron section--social section--grey-after'>
           <ul className='social'>
                <li className='social__item'>
                    <a className='social__link' href='http://www.youtube.com/ParkyGames/'>
                        <img className='social__icon' src={Youtube} alt='' aria-hidden='true' />
                        <span className='accessible'>YouTube</span>
                    </a>
                </li>

                <li className='social__item'>
                    <a className='social__link' href='https://www.instagram.com/parky_games/'>
                        <img className='social__icon' src={Instagram} alt='' aria-hidden='true' />
                        <span className='accessible'>Instagram</span>
                    </a>
                </li>

                <li className='social__item'>
                    <a className='social__link' href='https://twitter.com/parkygames'>
                        <img className='social__icon' src={Twitter} alt='' aria-hidden='true' />
                        <span className='accessible'>Twitter</span>
                    </a>
                </li>

                <li className='social__item'>
                    <a className='social__link' href='https://www.facebook.com/parkygames'>
                        <img className='social__icon' src={Facebook} alt='' aria-hidden='true' />
                        <span className='accessible'>Facebook</span> 
                    </a>
                </li>

                <li className='social__item'>
                    <a className='social__link' href='https://www.patreon.com/ParkyGames?ty=h'>
                        <img className='social__icon' src={Patreon} alt='' aria-hidden='true' />
                        <span className='accessible'>Patreon</span> 
                    </a>
                </li>
           </ul>
        </Section>
    );
};

export default Social;