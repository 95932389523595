import React, { useEffect } from 'react';
import { Link } from 'gatsby';

// Style Imports
import './Welcome.scss';

// Image imports
import Logo from '../../assets/images/content/logo.svg';

const Welcome = () => {

    useEffect(() => {
        window.addEventListener('scroll', initParallax);

        return () => {
            window.removeEventListener("scroll", initParallax);
        };
    }, []);

    const initParallax = function() {
        var offsetTop = window.pageYOffset;
        var parallaxLayers = document.getElementsByClassName("welcome__background");

        for (var i = 0; i < parallaxLayers.length; i++) {
            var speed = parallaxLayers[i].getAttribute('data-speed');
            var yPosition = -(offsetTop * speed / 100);
            parallaxLayers[i].setAttribute('style', 'transform: translate3d(0px, ' + yPosition + 'px, 0px)');
        }
    };

    return (
        <section className='welcome'>
            <div className='welcome__background' data-speed={5}></div>
            <div className='welcome__background' data-speed={10}></div>
            <div className='welcome__background' data-speed={15}></div>
            <div className='welcome__background' data-speed={20}></div>
            <div className='welcome__background' data-speed={30}></div>
            <div className='welcome__background' data-speed={40}></div>
            <div className='welcome__background' data-speed={55}></div>
            <div className='welcome__background' data-speed={75}></div>
            <div className='welcome__background' data-speed={100}></div>

            <div className='container'>
                <div className='welcome__container'>
                    <h1 className='accessible'>Homepage</h1>
                    <p className='welcome__text'>Welcome to the awkward world of</p>

                    <div className='welcome__image-wrapper'>
                        <img className='welcome__image' src={Logo} alt='' aria-hidden='true' />
                    </div>

                    <Link
                        className='welcome__link'
                        to='/about'
                        onClick={() => { window.scrollTo(0, 0); }}
                    >
                        Find out more
                    </Link>
                </div>
            </div>
        </section>
    );
};

export default Welcome;