import React, { useState, useEffect } from 'react';
import axios from 'axios';

// Component Imports
import Modal from '../../components/shared/Modal/Modal';
import VideoListing from '../../components/shared/VideoListing/VideoListing';

//Style Imports
import './PlaylistFeed.scss';

const PlaylistFeed = () => {
    const [playlists, setPlaylists] = useState([]);
    const [modalOpen, setModalOpen] = useState(false);
    const [playlistName, setPlaylistName] = useState('');
    const [videos, setVideos] = useState([]);

    useEffect(() => {
        getPlaylists();
    }, []);

    const openModal = (id) => {
        getVideos(id);
        setModalOpen(true);
    };

    const closeModal = () => {
        setModalOpen(false);
    };

    const getPlaylists = function() {
        let apiURL = 'https://www.googleapis.com/youtube/v3/playlists';
        const apiKey = 'AIzaSyBg46gJzXBOaq1hY_Nai4twIxSY49HbjBw';
        let part = 'snippet';
        const channelId = 'UCdqF8Uw8D6l3C4eHFkqqvbg';
        let maxResults = 3;

        axios.get(apiURL, {
            params: {
                part: part,
                channelId: channelId,
                key: apiKey,
                maxResults: maxResults
            }
        }).then(response => {
            setPlaylists(response.data.items);
        });
    };

    const getVideos = function(id) {
        let apiURL = 'https://www.googleapis.com/youtube/v3/playlistItems';
        const apiKey = 'AIzaSyBg46gJzXBOaq1hY_Nai4twIxSY49HbjBw';
        let part = 'snippet';
        let playlistId = id;
        const channelId = 'UCdqF8Uw8D6l3C4eHFkqqvbg';

        axios.get(apiURL, {
            params: {
                part: part,
                channelId: channelId,
                playlistId: playlistId,                
                key: apiKey,
            }
        }).then(response => {
            setVideos(response.data.items);
        });
    };

    return (
        <section className='section section--chevron section--black-after playlist-feed'>
            <div className='playlist-feed__inner'>
                <h2 className='section__heading'>Featured playlists</h2>
                {playlists.map((content, i) => (
                    <div className='playlist-feed__item' key={i+1}>
                        <article className='playlist-feed__article'>
                            <div className='playlist-feed__image-wrapper'>
                                <img className='playlist-feed__thumbnail' src={window.innerWidth <= 1024 ? content.snippet.thumbnails.maxres.url : content.snippet.thumbnails.medium.url} alt='' aria-hidden='true' />
                            </div>

                            <div className='playlist-feed__content'>
                                <h3 className='playlist-feed__title'>{content.snippet.title}</h3>
                                <p className='playlist-feed__description'>{content.snippet.description}</p>

                                <button
                                    className='playlist-feed__button'
                                    onClick={(event) => {
                                        event.preventDefault();
                                        setPlaylistName(content.snippet.title);
                                        openModal(content.id);
                                    }}
                                >
                                    Watch now
                                </button>
                            </div>
                        </article>
                    </div>
                ))}

                <Modal
                    modalOpen={modalOpen}
                    onRequestClose={closeModal}
                    heading={`Playlist - ${playlistName}`}
                >
                    <VideoListing content={videos} />
                </Modal>
            </div>
        </section>
    );
}

export default PlaylistFeed;