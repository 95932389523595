import React from 'react';

// Layout Imports
import Default from '../layouts/Default/Default';
import SEO from '../layouts/SEO/SEO';

// Component Imports
import Welcome from '../components/Welcome/Welcome';
import Social from '../components/Social/Social';
import VideoFeed from '../components/VideoFeed/VideoFeed';
import PlaylistFeed from '../components/PlaylistFeed/PlaylistFeed';
import LatestPosts from '../components/LatestPosts/LatestPosts';

const Index = (props) => {
  return (
    <Default
      location={props.location}
      title='Home'
    >
      <SEO
        title='Home'
        keywords={[`blog`, `gatsby`, `javascript`, `react`]}
      />

      <Welcome />

      <VideoFeed />

      <Social />

      <PlaylistFeed />

      <LatestPosts {...props} />
    </Default>
  );
};

export default Index;