import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';

// Style Imports
import './Card.scss';

const Card = ({
    className,
    variant,
    children,
}) => {
    //Class definitions
    const baseClass = 'card__wrapper';
    const variantClass = variant ? `card__wrapper--${variant}` : '';
    const customClass = className;
    const classes = [baseClass, variantClass, customClass].filter(Boolean).join(' ');

    return (
        <div className={classes}>
            {children}
        </div>
    );
}

Card.propTypes = {
    className: PropTypes.string,
    variant: PropTypes.string,
    children: PropTypes.node,
}

Card.Item = ({
    className,
    variant,
    heading,
    description,
    internal,
    external,
    imageSource,
    date,
    ctaText,
}) => {
   // Class definitions
   const baseClass = 'card';
   const variantClass = variant ? `card--${variant}` : '';
   const customClass = className;
   const classes = [baseClass, variantClass, customClass].filter(Boolean).join(' ');

   const content = (
       <>
            <div className='card__image-wrapper'>
                <img className='card__image' src={imageSource} alt='' aria-hidden='true' />
            </div>
            <h3 className='card__heading'>{heading}</h3>
            <p className='card__text' aria-hidden='true'>{date}</p>
            <p className='card__text' aria-hidden='true'>{description}</p>
            <span className='card__cta' aria-hidden='true'>{ctaText}</span>
        </>
    );

    return (
        <div className={classes}>
                {external ? (
                    <a className="card__link" href={external}>{content}</a>
                ) : (
                    <Link
                        className="card__link"
                        to={`/${internal}`}
                        onClick={() => window.scrollTo(0, 0)}
                    >
                        {content}
                    </Link>
                )}
        </div>
    );
};

Card.Item.defaultProps = {
    ctaText: 'Contact',
};

Card.Item.propTypes = {
    className: PropTypes.string,
    variant: PropTypes.oneOf(['bright']),
    heading: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    internal: PropTypes.string,
    external: PropTypes.string,
    imageSource: PropTypes.string,
    ctaText: PropTypes.string.isRequired,
};

export default Card;