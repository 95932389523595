import React, { useState } from 'react';
import PropTypes from 'prop-types';

// Component Imports
import Modal from '../Modal/Modal';
import VideoEmbed from '../VideoEmbed/VideoEmbed';

// Style Imports
import './VideoListing.scss';

const VideoListing = ({
    className,
    variant,
    content,
}) => {
    const [modalOpen, setModalOpen] = useState(false);
    const [videoId, setVideoId] = useState('');
    const [videoTitle, setVideoTitle] = useState('');

    // Class definitions
    const baseClass = 'video-listing';
    const variantClass = variant ? `video-listing--${variant}` : '';
    const customClass = className;
    const classes = [baseClass, variantClass, customClass].filter(Boolean).join(' ');

    const openModal = () => {
        setModalOpen(true);
    };

    const closeModal = () => {
        setModalOpen(false);
    };

    return (
        <section className={classes} aria-label='Video listing'>
            <ul className='video-listing__list'>
                {content.map((video, i) => (
                    <li className='video-listing__list-item' key={i}>
                        <a
                            className='video-listing__link'
                            href='/'
                            onClick={(event) => {
                                event.preventDefault();
                                setVideoId(video.snippet.resourceId.videoId)
                                setVideoTitle(video.snippet.title);
                                openModal();
                            }}
                            role='button'
                        >
                            <div className='video-listing__number'>
                                <span aria-hidden='true'>{i+1}</span>
                            </div>
                            <div className='video-listing__thumbnail-wrapper'>
                                <img className='video-listing__thumbnail' src={video.snippet.thumbnails.default.url} alt='Video thumbnail' />
                            </div>
                            
                            <div className='video-listing__content'>
                                <h3 className='video-listing__title'>{video.snippet.title}</h3>
                                <p className='video-listing__description'>{video.snippet.description}</p>
                            </div>
                        </a>
                    </li>
                ))}
            </ul>
            
            <Modal
                modalOpen={modalOpen}
                onRequestClose={closeModal}
                heading={videoTitle}
            >
                <div className='video-feed__embed'>
                    <VideoEmbed videoId={videoId} />
                </div>
            </Modal>
        </section>
    );
};

VideoListing.propTypes = {
    className: PropTypes.string,
    variant: PropTypes.string,
    content: PropTypes.array.isRequired,
};

export default VideoListing;
