import React from 'react';
import PropTypes from 'prop-types';

// Style Imports
import './Section.scss';

const Section = ({
    className,
    variant,
    heading,
    children,
    accent,
}) => {
    //Class definitions
    const baseClass = 'section';
    const variantClass = variant ? `section--${variant}` : '';
    const customClass = className;
    const classes = [baseClass, variantClass, customClass].filter(Boolean).join(' ');

    return (
        <section className={classes}>
            {accent && (
                <span className='section__accent'></span>
            )}

            <div className='section__container container'>
                {heading && (
                    <h2 className='section__heading'>{heading}</h2>
                )}
                {children}
            </div>
        </section>
    );
};

Section.propTypes = {
    className: PropTypes.string,
    variant: PropTypes.oneOf(['chevron', 'grey', 'blue', 'white']),
    heading: PropTypes.string,
    children: PropTypes.node,
    accent: PropTypes.bool,
};

export default Section;