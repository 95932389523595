import React from 'react';
import PropTypes from 'prop-types';

// Style Imports
import './VideoEmbed.scss';

const VideoEmbed = ({
    videoId,
}) => {
    return (
        <div className='video-embed'>
            <iframe
                title='Youtube video embed'
                frameBorder="0"
                scrolling="no"
                width="853"
                height="480"
                type="text/html"
                src={`https://www.youtube.com/embed/${videoId}?autoplay=0&fs=0&iv_load_policy=3&showinfo=1&rel=1&cc_load_policy=0&start=0&end=0&origin=https://youtube-embed.com`}
                allowFullScreen
                autoPlay
            ></iframe>
        </div>
    );
};

VideoEmbed.propTypes = {
    videoId: PropTypes.string.isRequired,
};

export default VideoEmbed;
