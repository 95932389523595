import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Slider from "react-slick";

// Carousel Styles
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

// Component Imports
import Section from '../../components/shared/Section/Section';
import Modal from '../../components/shared/Modal/Modal';
import VideoEmbed from '../../components/shared/VideoEmbed/VideoEmbed';

//Style Imports
import './VideoFeed.scss';

const VideoFeed = () => {
    const [recentVideos, setRecentVideos] = useState([]);
    const [modalOpen, setModalOpen] = useState(false);
    const [videoId, setVideoId] = useState('');
    const [videoTitle, setVideoTitle] = useState('');

    useEffect(() => {
        getVideos();
    }, []);

    const openModal = (url) => {
        setVideoId(url);
        setModalOpen(true);
    };

    const closeModal = () => {
        setModalOpen(false);
    };

    var settings = {
        accessibility: true,
        arrows: false,
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 4,
        autoplay: true,
        autoplaySpeed: 5000,
        slidesToScroll: 4,
        responsive: [
            {
                breakpoint: 568,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2
                }
            },
            {
                breakpoint: 960,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3
                }
            }
        ],
        className: 'video-feed'
    };

    const getVideos = function() {
        let apiURL = 'https://www.googleapis.com/youtube/v3/playlistItems';
        const apiKey = 'AIzaSyBg46gJzXBOaq1hY_Nai4twIxSY49HbjBw';
        let part = 'snippet';
        let playlistId = 'UUdqF8Uw8D6l3C4eHFkqqvbg';
        const channelId = 'UCdqF8Uw8D6l3C4eHFkqqvbg';
        let maxResults = 12;

        axios.get(apiURL, {
            params: {
                part: part,
                channelId: channelId,
                playlistId: playlistId,                
                key: apiKey,
                maxResults: maxResults
            }
        }).then(response => {
            setRecentVideos(response.data.items);
        });
    };

    return (
        <Section className='video-feed__wrapper section--chevron section--navy-after'>
            <h2 className='accessible'>Recent videos</h2>
            {recentVideos.length > 0 && (
                <>
                    <Slider {...settings}>
                        {recentVideos.map((content, i) => (
                            <article className='video-feed__article' key={i+1}>
                                <a className='video-feed__link' href='/' onClick={(event) => {
                                    event.preventDefault();
                                    setVideoTitle(content.snippet.title);
                                    openModal(content.snippet.resourceId.videoId);
                                }}>
                                    <div className='video-feed__image-wrapper'>
                                        <img className='video-feed__thumbnail' src={window.innerWidth <= 568 ? content.snippet.thumbnails.maxres.url : content.snippet.thumbnails.medium.url} alt='' aria-hidden='true' />
                                    </div>
                                    
                                    <div className='video-feed__content'>
                                        <h3 className='video-feed__title'>{content.snippet.title}</h3>
                                        <p className='video-feed__text'>Watch now</p>
                                    </div>
                                </a>
                            </article>
                        ))}
                    </Slider>

                    <Modal
                        modalOpen={modalOpen}
                        onRequestClose={closeModal}
                        heading={videoTitle}
                    >
                        <VideoEmbed videoId={videoId} />
                    </Modal>
                </>
            )}
        </Section>
    );
}

export default VideoFeed;