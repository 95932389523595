import React, { useEffect, useState } from "react";
import PropTypes from 'prop-types';
import Modal from 'react-modal';

// Style Imports
import './Modal.scss';

Modal.setAppElement('#___gatsby');

const ModalWindow = ({
    children,
    modalOpen,
    onRequestClose,
    heading,
}) => {
    const [animated, setAnimated] = useState(false);

    // Modal Styles
    const modalStyles = {
        overlay: {
            zIndex: '10',
            backgroundColor: animated ? 'rgba(0, 0, 0, .85)' : 'rgba(0, 0, 0, 0)',
            transition: 'background-color ease .75s',
        },
    };

    const handleAnimation = () => {
        setTimeout(function() {
            setAnimated(true);
        }, 0);
    };

    useEffect(() => {
        handleAnimation();
    }, []);
    
    return (
        <Modal
            className={`modal${animated ? ' modal--animated' : ''}`}
            isOpen={modalOpen}
            onRequestClose={onRequestClose}
            style={modalStyles}
            contentLabel={heading}
        >
            <div className='modal__header'>
                {heading && (
                    <h2 className='modal__heading'>{heading}</h2>
                )}
                <button className='modal__button' onClick={onRequestClose}>
                    <span className='accessible'>Close modal overlay</span>
                </button>
            </div>
            
            <div className='modal__content'>
                {children}
            </div>
        </Modal>
    );
};

ModalWindow.propTypes = {
    children: PropTypes.node.isRequired,
    modalOpen: PropTypes.bool.isRequired,
    onRequestClose: PropTypes.func.isRequired,
    heading: PropTypes.string,
};

export default ModalWindow;