import React from "react";
import { graphql, StaticQuery } from "gatsby";
import Slider from "react-slick";

// Component Imports
import Card from "../shared/Card/Card";

// Style Imports
import "./LatestPosts.scss";

import Recording from "../../assets/images/icons/article.svg";

const LatestPosts = () => {
    return (
        <StaticQuery
            query={graphql`
                query {
                    allMdx(
                        sort: { fields: [frontmatter___date], order: DESC }
                            limit: 9
                        ) {
                        edges {
                            node {
                                frontmatter {
                                    date(formatString: "MMMM DD, YYYY")
                                    title
                                    description
                                    path
                                }
                            }
                        }
                    }
                }
            `}
            render={data => {
                const settings = {
                    accessibility: true,
                    arrows: false,
                    dots: true,
                    infinite: data.allMdx.edges.length > 3,
                    speed: 500,
                    slidesToShow: 3,
                    autoplay: true,
                    autoplaySpeed: 5000,
                    slidesToScroll: 3,
                    responsive: [
                        {
                            breakpoint: 768,
                            settings: {
                            slidesToShow: 1,
                            slidesToScroll: 1,
                            },
                        },
                        {
                            breakpoint: 960,
                            settings: {
                            slidesToShow: 2,
                            slidesToScroll: 2,
                            },
                        },
                    ],
                    className: "latest-posts__feed",
                };

                return (
                    <section className='section section--black latest-posts'>
                        <h2 className="section__heading">Latest Blog Posts</h2>

                        <div className="section__container container">
                            <Slider {...settings}>
                                {data.allMdx.edges.map((post, index) => (
                                    <article className="latest-posts__article" key={index}>
                                        <Card.Item
                                            variant="bright"
                                            imageSource={Recording}
                                            internal={`blog/${post.node.frontmatter.path}`}
                                            heading={post.node.frontmatter.title}
                                            description={post.node.frontmatter.description}
                                            date={post.node.frontmatter.date}
                                            ctaText='Read more'
                                        />
                                    </article>
                                ))}
                            </Slider>
                        </div>
                    </section>
                )
            }}
        />
    );
};

export default LatestPosts;